import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { PaymentPage } from "../pages/payment/PaymentPage";
import { PaymentProvider } from "../context/payment/PaymentContext";
import { AuthProvider, useAuth } from "../context/auth/AuthContext";
import { LocalProvider } from "../context/local/LocalContext";
import { LoginPage } from "../pages/public/auth/login/LoginPage";
import { PrivatePage } from "../pages/private/privateLayout/PrivatePage";
import { OrderHistory } from "../pages/private/orderHistory/OrderHistoryPage";
import { OrderTrackingPage } from "../pages/private/orderTracking/OrderTrackingPage";
import { MapOrderTracking } from "../pages/private/orderTracking/mapOrderTracking/MapOrderTracking";
import FormAluno from "../pages/public/auth/loginAluno/LoginAlunoPage";
import { MeepCardPaymentConsumer, MeepCardPaymentProvider } from "../context/meepCardPayment/_MeepCardPaymentContext";
import MeepCardPaymentPage from "../pages/meepCardPayment/_MeepCardPaymentPage";
import { CatalogProvider } from "../context/catalog/CatalogContext";
import AuthPage from "pages/public/auth/AuthPage";
import ProductsPage from "pages/public/catalog/products/ProductsPage";
import StoresPage from "pages/public/catalog/stores/StoresPage";
import OnSucessPayment from "pages/payment/onSucessPayment/OnSucessPayment";
import './Routes.css'
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import { CartProvider } from "context/cart/CartContext";
import PaymentLinkPage from "pages/public/paymentLink/PaymentLinkPage";
import PaymentGuestPage from "pages/public/paymentGuest/PaymentGuestPage";
import ProfilePage from "pages/public/social/profile/ProfilePage";
import ScheduledPage from "pages/public/schedule/pages/schedules/ScheduledPage";
import TourPage from "pages/public/schedule/pages/tour/presentation/pages/TourPage";
import SelecionarPagamento from "pages/public/selecionarLogin/SelecionarLogin";
import ProductsTourPage from "pages/public/schedule/pages/tour/presentation/pages/productsTour/ProductsTourPage";
import { FormTourPage } from "pages/public/schedule/pages/tour/presentation/pages/formTour/FormTourPage";
import { MoreDetails } from "pages/public/schedule/pages/tour/presentation/pages/moreDetails/MoreDetails";
import ReservesPage from "pages/public/schedule/pages/tour/presentation/pages/reserves/ReservesPage";
import TourCartProvider from "components/genericCart/CartContext";
import { AboutProject } from "pages/public/schedule/pages/tour/presentation/pages/aboutProject/AboutProject";
import ExternalAuthPage from "pages/public/auth/externalLogin/ExternalAuthPage";
import ExternalNewCardPage from "pages/externalNewCard/ExternalNewCardPage";
import TrackWidget from "pages/customerSurveys/TrackWidget";
import PaymentLink from "pages/public/paymentLink/PaymentLink";

// import { PaymentResume } from "./pages/public/schedule/pages/tour/paymentResume/PaymentResumePage";
export const Routes = () => {
  const { loadToken, isAuth } = useAuth();
  const { goBack } = useHistory();
  // return   !loadToken ? (
  //   <CircularProgress />
  // ) 
  // : 
  return (
    <Route path={`/`}>
      <Switch>

        <Route exact path="/survey/:ownerId/:surveyId" >
          <TrackWidget />
        </Route>
        <Route exact path="/Profile/:profileId?" >
          <ProfilePage />
        </Route>

        <Route exact path="/">
          <Redirect to={"/pedidos"}></Redirect>
        </Route>

        <Route exact path="/paymentSchool/">
          <MeepCardPaymentProvider>
            <MeepCardPaymentConsumer>
              {({ meepCardData }) =>
                meepCardData ? (
                  <MeepCardPaymentPage />
                ) : (
                  <LoginPage>
                    <FormAluno></FormAluno>
                  </LoginPage>
                )
              }
            </MeepCardPaymentConsumer>
          </MeepCardPaymentProvider>
        </Route>

        <Route path={`/tracking/:orderId`} exact>
          <OrderTrackingPage />
        </Route>

        <Route path={`/mapTracking/:orderId`} exact>
          <MapOrderTracking />
        </Route>


        <Route path={`/PaymentGuest`} exact>
          <PaymentGuestPage />
        </Route>

        <Route path={`/PaymentGuest/onSucessPayment`} exact>
          <OnSucessPayment redirectDisabled />
        </Route>

        {/* private */}

        <Route path={`/pedidos`}>
          <AuthProvider>
            <PrivatePage onClickBack={() => { goBack() }}>
              <OrderHistory></OrderHistory>
            </PrivatePage>
          </AuthProvider>
        </Route>

        <Route path={`/scheduled`}>
          <AuthProvider>
            <PrivatePage onClickBack={() => { goBack() }}>
              <ScheduledPage></ScheduledPage>
            </PrivatePage>
          </AuthProvider>
        </Route>


        <Route path={`/tracking/:orderId`} exact>
          <AuthProvider>
            <PrivatePage>
              <OrderTrackingPage />
            </PrivatePage>
          </AuthProvider>
        </Route>

        <Route path={`/payment`}>
          <Switch>
            <Route exact path={`/payment/`}>
              <AuthProvider>
                <PaymentProvider>
                  <PaymentPage />
                </PaymentProvider>
              </AuthProvider>
            </Route>
            <Route path={`/payment/confirm-payment/:storeId`}>
              <OnSucessPayment />
            </Route>
          </Switch>
        </Route>


        <Route path="/:url/selecionarPagamento">

          <LocalProvider>
            <LoginPage>
              <SelecionarPagamento></SelecionarPagamento>
            </LoginPage>
          </LocalProvider>
        </Route>

        <Route path={`/:url/payment`}>
          <Switch>




            <Route exact path={`/:url/payment/`}>
              <AuthProvider>
                <PaymentProvider>
                  <PaymentPage />
                </PaymentProvider>
              </AuthProvider>
            </Route>
            <Route path={`/:url/payment/confirm-payment/:storeId`}>
              <OnSucessPayment />
            </Route>
          </Switch>
        </Route>




        <Route path={`/:url/schedule/tour`}>
          <AuthProvider disableRedirect>
            <LocalProvider>




              <TourCartProvider>
                <Switch>
                  <Route exact path={`/:url/schedule/tour/`}>
                    <TourPage></TourPage>
                  </Route>
                  <Route exact path={`/:url/schedule/tour/aboutProject`}>
                    <AboutProject></AboutProject>
                  </Route>
                  <Route exact path={`/:url/schedule/tour/reserves`}>
                    <ReservesPage></ReservesPage>
                  </Route>
                  <Route exact path={`/:url/schedule/tour/:storeId`}>
                    <ProductsTourPage></ProductsTourPage>
                  </Route>
                  <Route exact path={`/:url/schedule/tour/:storeId/moreDetails/:serviceId`}>
                    <MoreDetails></MoreDetails>
                  </Route>
                  <Route exact path={`/:url/schedule/tour/:storeId/form/:serviceId/:cartId?`}>
                    <FormTourPage></FormTourPage>
                  </Route>
                  {/* <Route exact path={`/:url/schedule/tour/:storeId/resumePayment`}>
                    <PaymentResume></PaymentResume>
                  </Route> */}
                  <Route path={`/:url/schedule/tour/login`}>
                    <AuthPage></AuthPage>
                  </Route>

                </Switch>
              </TourCartProvider>
            </LocalProvider>
          </AuthProvider>
        </Route>




        <Route exact path={`/login`}>
          <AuthProvider>
            <AuthPage></AuthPage>
          </AuthProvider>
        </Route>
        <Route exact path={`/externalLogin`}>
          <AuthProvider disableRedirect>
            <ExternalAuthPage></ExternalAuthPage>
          </AuthProvider>
        </Route>
        <Route exact path={`/externalAddNewCard/:token?/:userId?`}>
          <AuthProvider >
            <ExternalNewCardPage />
          </AuthProvider>
        </Route>

        <Route exact path={`/login`}>
          <AuthProvider>
            <AuthPage></AuthPage>
          </AuthProvider>
        </Route>


        {/* <Route path="/">
              <Redirect to={"/login"}></Redirect>
            </Route> */}



        <Route exact path={`/:url/externalLogin`}>
          <AuthProvider disableRedirect>
            <ExternalAuthPage></ExternalAuthPage>
          </AuthProvider>
        </Route>
        <Route exact path={`/:url/externalAddNewCard/:token?/:userId?`}>
          <AuthProvider >
            <ExternalNewCardPage />
          </AuthProvider>
        </Route>



        <Route path={`/paymentlink/:paymentLinkId`}>
          <PaymentLink />
        </Route>

        <Route path={`/:url`}
          render={({ location }) =>
            <AuthProvider disableRedirect>
              <CartProvider>
                <CatalogProvider>
                  <TransitionGroup>
                    <CSSTransition
                      key={location.key}
                      classNames={"fadeTransition"}
                      timeout={600}
                    >
                      <Switch location={location}>
                        <Route exact path={`/:url/`}>
                          <StoresPage />
                        </Route>
                        <Route exact path={`/:url/:storeId`}>
                          <ProductsPage />
                        </Route>
                        <Route exact path={`/:url/:storeId/product/:productId`}>
                          <ProductsPage />
                        </Route>
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                </CatalogProvider>
              </CartProvider>
            </AuthProvider>

          }
        />

        <Route path={`/*`}>404 not found</Route>

      </Switch>
    </Route>
  );
};
