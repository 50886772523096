import React, { ChangeEvent, FC, useCallback } from "react";
import styles from "./TicketForm.module.scss";
import { Container, Grid, TextField } from "@material-ui/core";
import { IPayerErrors } from "./validate";
import { cpfMask } from "cross-cutting/masks/cpf";

export interface ITicketFormValues {
  PayerFirstName: string;
  PayerLastName: string;
  PayerDocumentNumber: string;
  PayerEmail: string;
}

export interface ITicketForm {
  values: ITicketFormValues;
  handleChangeValues: (name: string, value: string) => void;
  errors: IPayerErrors;
}

const TicketForm: FC<ITicketForm> = ({
  values,
  handleChangeValues,
  errors,
}) => {
  const onChangeHandle = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      ev.persist();
      let value =
        ev.target.name === "PayerDocumentNumber"
          ? ev.target.value.replace(/[./-]/g, "")
          : ev.target.value;
      handleChangeValues(ev.target.name, value);
    },
    [handleChangeValues]
  );

  return (
    <div id={styles.TicketForm}>
      <Container maxWidth="xs" className={styles.container}>
        <form>
          <Grid spacing={2} alignContent="center" alignItems="center" container>
            <Grid item xs={12}>
              <TextField
                required
                autoComplete={"off"}
                helperText={errors.PayerFirstName}
                error={!!errors.PayerFirstName}
                variant="outlined"
                fullWidth
                onChange={onChangeHandle}
                value={values.PayerFirstName}
                name="PayerFirstName"
                label="Nome"
                placeholder="Nome"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                autoComplete={"off"}
                helperText={errors.PayerLastName}
                error={!!errors.PayerLastName}
                variant="outlined"
                fullWidth
                onChange={onChangeHandle}
                value={values.PayerLastName}
                name="PayerLastName"
                label="Sobrenome"
                placeholder="Sobrenome"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                autoComplete={"off"}
                helperText={errors.PayerDocumentNumber}
                error={!!errors.PayerDocumentNumber}
                variant="outlined"
                fullWidth
                onChange={onChangeHandle}
                value={cpfMask(values.PayerDocumentNumber)}
                name="PayerDocumentNumber"
                label="CPF"
                placeholder="CPF"
                inputProps={{ maxLength: 14 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                autoComplete={"off"}
                helperText={errors.PayerEmail}
                error={!!errors.PayerEmail}
                variant="outlined"
                fullWidth
                onChange={onChangeHandle}
                value={values.PayerEmail}
                name="PayerEmail"
                label="E-mail"
                placeholder="E-mail"
              />
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
};

export default TicketForm;
