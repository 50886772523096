import {
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { moneyMaskNumber } from "cross-cutting/masks/money";
import { IPaymentLinkResponse } from "models/paymentLink/IPaymentLinkResponse";
import React, { FC, useMemo, useRef } from "react";
import CreditForm from "../creditForm/CreditForm";
import styles from "./Credit.module.scss";
import { UseCredit } from "./UseCredit";
import { useUi } from "context/ui/UIContext";
import { ReactComponent as Check } from "assets/icons/check.svg";
import { ReactComponent as Load } from "assets/icons/loading.svg";
import NumberToMoneyString from "utils/mask/NumberToMoney";

export interface ICredit {
  paymentLink: IPaymentLinkResponse;
}

const Credit: FC<ICredit> = ({ paymentLink }) => {
  const {
    card,
    setCard,
    onClickSend,
    installment,
    setInstallment,
    approved,
    loading,
    errors,
    address,
    setAddress,
    owner,
    setOwner,
  } = UseCredit(paymentLink);

  const { isMobile } = useUi();

  const formRef = useRef<HTMLDivElement>(null);

  const renderOneInstallment = useMemo(() => {
    if (paymentLink.installments.length === 1) {
      setInstallment(paymentLink.installments[0]);
      return (
        <TextField
          className={styles.installmentContainer}
          required
          variant="outlined"
          fullWidth
          value={`${
            paymentLink.installments[0].description
          } - Total ${moneyMaskNumber(paymentLink.installments[0].price)}`}
          label="Número de parcelas"
          disabled
        />
      );
    } else {
      return <></>;
    }
  }, [paymentLink, setInstallment]);

  if (approved.length) {
    return (
      <div className={styles.approved}>
        <Check />
        <p>
          {paymentLink.successMessage
            ? paymentLink.successMessage
            : "Transação aprovada!"}
        </p>
        <p>
          Valor pago: <b> {NumberToMoneyString(Number(paymentLink.price))}</b>
        </p>
        <p>
          Pedido: <b> {approved}</b>
        </p>
      </div>
    );
  }

  return (
    <Container maxWidth="md">
      <div
        id={styles.Credit}
        style={{ marginBottom: isMobile ? 16 : undefined }}
      >
        <div className={styles.title}>
          Realize o pagamento através do Cartão de crédito
        </div>

        <div
          className={styles.container}
          ref={formRef}
          style={{ height: isMobile ? "auto" : undefined }}
        >
          <CreditForm
            card={card}
            setCard={setCard}
            formRef={formRef}
            errors={errors}
            address={address}
            setAddress={setAddress}
            owner={owner}
            setOwner={setOwner}
          />

          <Grid
            item
            xs={12}
            style={{ padding: isMobile ? undefined : "0 14px" }}
          >
            {paymentLink.installments.length === 1 ? (
              renderOneInstallment
            ) : (
              <TextField
                className={styles.installmentContainer}
                fullWidth
                variant="outlined"
                label="Número de parcelas"
                value={installment}
                onChange={(ev) =>
                  setInstallment(
                    paymentLink?.installments.find(
                      (item) =>
                        item.installment === Number.parseInt(ev.target.value)
                    )
                  )
                }
                select
              >
                {paymentLink?.installments.map((installment) => (
                  <MenuItem value={installment.installment}>
                    {installment.description} - Total{" "}
                    {moneyMaskNumber(installment.price)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
        </div>
        <Button
          className={!card ? styles.disabled : ""}
          size="large"
          variant="contained"
          color="primary"
          onClick={onClickSend}
        >
          {loading ? <Load /> : "Finalizar pagamento"}
        </Button>
      </div>
    </Container>
  );
};

export default Credit;
