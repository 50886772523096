import { validateCPF } from "utils/mask/cpf";
import { ITicketFormValues } from "./TicketForm";

export interface IPayerErrors {
  PayerFirstName?: string;
  PayerLastName?: string;
  PayerDocumentNumber?: string;
  PayerEmail?: string;
}

export const validate = (
  payer: ITicketFormValues,
  setErrors: React.Dispatch<React.SetStateAction<IPayerErrors>>
) => {
  setErrors({
    PayerFirstName: "",
    PayerLastName: "",
    PayerDocumentNumber: "",
    PayerEmail: "",
  });

  let validForm = true;

  if (!payer.PayerFirstName || payer.PayerFirstName.length < 3) {
    setErrors((prev) => ({ ...prev, PayerFirstName: "Nome obrigatório" }));
    validForm = false;
  }

  if (!payer.PayerLastName || payer.PayerLastName.length < 3) {
    setErrors((prev) => ({ ...prev, PayerLastName: "Sobrenome obrigatório" }));
    validForm = false;
  }

  if (!payer.PayerDocumentNumber) {
    setErrors((prev) => ({ ...prev, PayerDocumentNumber: "CPF obrigatório" }));
    validForm = false;
  } else if (!validateCPF(payer.PayerDocumentNumber)) {
    setErrors((prev) => ({ ...prev, PayerDocumentNumber: "CPF inválido" }));
    validForm = false;
  }

  const validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!payer.PayerEmail) {
    setErrors((prev) => ({ ...prev, PayerEmail: "Email obrigatório" }));
    validForm = false;
  } else if (!validEmail.test(payer.PayerEmail)) {
    setErrors((prev) => ({ ...prev, PayerEmail: "Email inválido" }));
    validForm = false;
  }

  return validForm;
};
