import { useUi } from 'context/ui/UIContext';
import { moneyMaskNumber } from 'cross-cutting/masks/money';
import { ProductType } from 'models/catalog/enums/ProductType';
import { IOrder } from 'models/order/IOrder';
import { IPaymentLinkInstallments, IPaymentLinkResponse } from 'models/paymentLink/IPaymentLinkResponse';
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import SendOrderApi from 'services/Api/order/SendOrderApi';
import PaymentLinkApi from 'services/Api/paymentLink/PaymentLinkApi';
import { v4 } from 'uuid';

const getInstallments = (price: number, maximumInstallments: number, interestPerInstallment: number): IPaymentLinkInstallments[] => {
  var installments: IPaymentLinkInstallments[] = [];

  if (maximumInstallments > 1)
  {
      for (let installment: number = 1; installment <= maximumInstallments; installment++)
      {
          var totalPrice = calculatePriceWithInterest(price, interestPerInstallment, installment);
          installments.push(
          {
              installment: installment,
              price: totalPrice,
              description: `${installment}x  ${moneyMaskNumber(totalPrice / installment)}`
          });
      }
  }
  else
  {
      installments.push(
      {
          installment: 1,
          price: price,
          description: `1x ${moneyMaskNumber(price)}`
      });
  }
  
  return installments;
}

const calculatePriceWithInterest = (price: number, interestPerInstallment: number, installment: number): number => {
  let totalPrice = price;

  if (interestPerInstallment > 0 && installment > 1)
  {
      const interest = totalPrice * (((interestPerInstallment / 100) * 100) / 100);
      totalPrice = totalPrice + (interest * installment);
  }

  return totalPrice;
}

export const UsePaymentLink = (
  valueRef?: React.RefObject<HTMLInputElement>
) => {
  const [paymentLink, setPaymentiLink] = useState<IPaymentLinkResponse>();
  const [paymentLinkValue, setPaymentLinkValue] = useState("");

  const { showLoading, hideLoading } = useUi();

  const { paymentLinkId } = useParams<{ paymentLinkId: string }>();

  const { push } = useHistory();

  const handleChangeValue = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = ev.target.value;
      value = value.replace(/[^\d]/g, "");
      value = value.slice(0, -2) + "," + value.slice(-2);
      value = value.split(",")[0].length === 0 ? "0" + value : value;

      const price = parseFloat(value.replace(",", "."));

      setPaymentLinkValue(price === 0 ? "" : value);
      setPaymentiLink((prev) => {
        if (prev) {
          return {
            ...prev,
            price,
            installments: getInstallments(price, prev.maximumInstallments, prev.interestPerInstallment)
          };
        }
      });
    },
    []
  );

  const generateOrderAndRedirect = useCallback(() => {
    const sendOrderApi = SendOrderApi();
    if (paymentLink) {
      const newOrder: IOrder = {
        id: v4(),
        total: paymentLink.price,
        items: [
          {
            id: paymentLink.productId,
            name: paymentLink.product.nome,
            category: "",
            price: paymentLink.price,
            totalPrice: paymentLink.price,
            description: "",
            productPrice: paymentLink.price,
            productId: paymentLink.productId,
            productType: ProductType.Produto,
            quantity: 1,
            imageUrl: "",
          },
        ],
      };

      showLoading();
      sendOrderApi
        .sendPreOrder(newOrder, paymentLink.localId)
        .then((response) => {
          push(`/payment?orderId=${response.data.pedidoId}`);
        })
        .finally(() => {
          hideLoading();
        });
    }
  }, [hideLoading, paymentLink, push, showLoading]);

  const goToPayment = useCallback(() => {
    if (paymentLink) {
      push(`/paymentguest?paymentLinkId=${paymentLink.id}`);
    }
  }, [paymentLink, push]);

  const inputMoneyMask = useCallback(
    (value?: string) => {
      if (!value) {
        return undefined;
      }
      value = value.replace(/\D/g, "");
      while (value.length < 3) {
        value = "0" + value;
      }
      value = value.replace(/^0+(?=\d{3,})/, "");

      let maskedValue = "R$ " + value.slice(0, -2) + "," + value.slice(-2);
      if (value === "000") {
        if (valueRef && valueRef.current) {
          valueRef.current.value = "";
        }
        return undefined;
      }
      return maskedValue;
    },
    [valueRef]
  );

  useEffect(() => {
    if (paymentLinkId) {
      const paymentLinkApi = PaymentLinkApi();
      showLoading();
      paymentLinkApi
        .getPaymentProduct(paymentLinkId)
        .then((response) => {
          setPaymentiLink(response.data);
          setPaymentLinkValue(
            response.data.price.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
              minimumFractionDigits: 2,
            })
          );
        })
        .finally(() => {
          hideLoading();
        });
    }
    return () => {};
  }, [hideLoading, inputMoneyMask, paymentLinkId, showLoading]);

  return {
    paymentLink,
    generateOrderAndRedirect,
    goToPayment,
    handleChangeValue,
    inputMoneyMask,
    paymentLinkValue,
  };
};
