import { IPaymentGuestRequest } from "models/paymentGuest/IPaymentGestRequest";
import {
  IPaymentLinkResponse,
  PixPaymentRequest,
  PixPaymentResponse,
  TicketGetResponse,
  TicketPostResponse,
  TicketRequest,
} from "models/paymentLink/IPaymentLinkResponse";
import { Api } from "../Api";

const PaymentLinkApi = () => {
  const api = Api();

  const getPaymentProduct = (paymentLinkId: string) => {
    return api.get<IPaymentLinkResponse>(
      `/mepaylink/GetLinkById/${paymentLinkId}`
    );
  };

  const getPaymentGuestSendPay = (
    paymentGuestRequest: IPaymentGuestRequest
  ) => {
    return api.post<IPaymentLinkResponse>(`/PayLink`, paymentGuestRequest);
  };

  const pixPayment = async (payRequest: PixPaymentRequest) => {
    const response = await api.post<PixPaymentResponse>("/public/Pix/", payRequest);
    return response.data;
  };

  const pixPaymentStatus = async (paymentId: string) => {
    const response = await api.get<PixPaymentResponse>(`/public/Pix/${paymentId}`);
    return response.data;
  };

  const ticketPayment = async (payRequest: TicketRequest): Promise<TicketPostResponse> => {
    const response = await api.post(
      `/third/pay/boleto`, payRequest
    );
    return response.data.Result;
  };

  const ticketPaymentStatus = async (paymentId: string): Promise<TicketGetResponse> => {
    const response = await api.get(
      `/third/pay/boleto/${paymentId}`
    );
    return response.data[0];
  };

  return {
    getPaymentProduct,
    getPaymentGuestSendPay,
    pixPayment,
    pixPaymentStatus,
    ticketPayment,
    ticketPaymentStatus,
  };
};

export default PaymentLinkApi;
