import React, { FC } from "react";
import styles from "./Footer.module.scss";
import logo from "../../../../../res/images/paymentLink/meep-footer.png";
import FacebookImg from 'assets/icons/facebook_round.svg'
import InstagramImg from 'assets/icons/instagram_round.svg'
import YoutubeImg from 'assets/icons/youtube_round.svg'
import LinkedinImg from 'assets/icons/linkedin_round.svg'
import { useUi } from "context/ui/UIContext";

export interface IFooter {}

const Footer: FC<IFooter> = ({}) => {
  const { isMobile } = useUi();
  return (
    <div id={styles.Footer} style={{paddingTop: isMobile ? 10 : undefined}}>
      <img src={logo} alt="logo_meep" width={200} />
      <div className={styles.icons}>
        <a
          href="https://www.facebook.com/share/qboXg9v2kdBT3jcv/?mibextid=LQQJ4d"
          target="_blank"
          rel="noopener noreferrer"
          title="facebook"
        >
          <img src={FacebookImg} className={styles.iconFacebook} alt="" />
        </a>
        <a
          href="https://youtube.com/@appmeep?si=BCrpUqMqGvlvusc6"
          target="_blank"
          rel="noopener noreferrer"
          title="youtube"
        >
          <img src={YoutubeImg} className={styles.iconYoutube} alt="" />
        </a>
        <a
          href="https://www.linkedin.com/company/meep-app/"
          target="_blank"
          rel="noopener noreferrer"
          title="instagram"
        >
          <img src={LinkedinImg} className={styles.iconInstagram} alt="" />
        </a>
        <a
          href="https://www.instagram.com/app.meep?igsh=MTZzd3VvdnI5Mzd2Yw=="
          target="_blank"
          rel="noopener noreferrer"
          title="instagram"
        >
          <img src={InstagramImg} className={styles.iconInstagram} alt="" />
        </a>
      </div>
      <b>© 2024 – Meep Soluções S.A – CNPJ: 28.302.604/0001-01</b>
    </div>
  );
};

export default Footer;
