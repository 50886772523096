import { Button, Skeleton } from "@mui/material";
import { ReactComponent as Copy } from "assets/icons/copy.svg";
import { ReactComponent as Check } from "assets/icons/check.svg";
import { ReactComponent as Load } from "assets/icons/loading.svg";
import { useUi } from "context/ui/UIContext";
import {
  IPaymentLinkResponse,
  PixPaymentResponse,
} from "models/paymentLink/IPaymentLinkResponse";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import PaymentLinkApi from "services/Api/paymentLink/PaymentLinkApi";
import NumberToMoneyString from "utils/mask/NumberToMoney";
import styles from "./Pix.module.scss";
import { PaymentApi } from "services/Api/payment/PaymentApi";
import { PaymentType } from "models/payment/IPay";

const REQUEST_TIMER_DELAY = 20;

let requestDelayTimeout: NodeJS.Timeout;

export interface IPix {
  paymentLink: IPaymentLinkResponse;
}

const Pix: FC<IPix> = ({ paymentLink }) => {
  const { toast, isMobile } = useUi();

  const paymentId = useRef<string | null>(null);

  const [loading, setLoading] = useState(false);
  const [pix, setPix] = useState<PixPaymentResponse | null>(null);
  const [requestCounter, setRequestCounter] = useState(REQUEST_TIMER_DELAY);
  const [showPix, setShowPix] = useState(false);

  const allowPooling = useRef(true);

  const generatePix = useCallback(() => {
    if (paymentLink) {
      setShowPix(true);
      const paymentApi = PaymentApi();
      setLoading(true);
      paymentApi
        .confirmarPagamentoPedido({
          localClienteId: paymentLink.localId,
          tipoPagamentoId: PaymentType.DEBITO,
          customDeliveryDescription: paymentLink.description,
          resumoPedido: {
            valorPedido: paymentLink.price,
            itens: [
              {
                nome: paymentLink.product.nome,
                produtoId: paymentLink.product.id,
                quantidade: 1,
                valorUnitario: paymentLink.price,
              },
            ],
          },
          gerarPIX: true,
          canalVenda: 1,
        })
        .then((res) => {
          paymentId.current = res.data.orderId ?? "";
          setPix({
            ownerId: "",
            paymentId: res.data.orderId ?? "",
            qrCodeBase64: res.data.qrcodeBase64 ?? "",
            qrCodeLink: res.data.qrcodeLink ?? "",
            status: res.data.isPaymentPending ? "pending" : "approved",
            value: paymentLink.price,
          });
        })
        .catch((_err) => {
          setShowPix(false);
          toast("Falha ao obter dados do Pix", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => {
      paymentId.current = null;
    };
  }, [paymentLink, toast]);

  useEffect(() => {
    requestDelayTimeout = setTimeout(() => {
      if (pix?.status !== "approved") {
        setRequestCounter(requestCounter - 1);
      }
    }, 1000);
    return () => {
      clearTimeout(requestDelayTimeout);
    };
  }, [pix, requestCounter]);

  useEffect(() => {
    if (
      requestCounter <= 0 &&
      paymentId.current &&
      pix?.status === "pending" &&
      allowPooling.current
    ) {
      allowPooling.current = false;
      const paymentLinkApi = PaymentLinkApi();
      paymentLinkApi
        .pixPaymentStatus(paymentId.current)
        .then((res) => {
          setPix(res);
        })
        .finally(() => {
          setRequestCounter(REQUEST_TIMER_DELAY);
          allowPooling.current = true;
        });
    }
  }, [pix, requestCounter]);

  const copyPixToClipboard = useCallback(() => {
    if (pix?.qrCodeLink) {
      navigator.clipboard.writeText(pix.qrCodeLink);
      toast("Código copiado para área de transferência", "success");
    }
  }, [pix, toast]);

  if (pix?.status === "approved") {
    return (
      <div className={styles.approved}>
        <Check />
        <p>
          {paymentLink.successMessage
            ? paymentLink.successMessage
            : "Pagamento aprovado!"}
        </p>
        <p>
          Valor pago: <b> {NumberToMoneyString(Number(pix?.value))}</b>
        </p>
        <p>
          Pedido: <b> {pix?.paymentId}</b>
        </p>
      </div>
    );
  }

  return (
    <div id={styles.Pix}>
      <div className={styles.title}>Realize o pagamento através do PIX</div>
      <div className={styles.description}>
        Faça o pagamento pelo seu banco favorito e não se preocupe, seu pedido
        será <b>validado automaticamente</b>
      </div>
      {showPix && (
        <div className={styles.pixContainer}>
          {loading || !pix ? (
            <span className={styles.img}>
              <Skeleton width={163} height={163} variant="rectangular" />
            </span>
          ) : (
            <img
              src={`data:image/jpeg;base64,${pix?.qrCodeBase64}`}
              alt="qr_code"
            />
          )}
          <div>
            <div>
              {loading || !pix ? (
                <Skeleton width={50} height={26} variant="text" />
              ) : (
                NumberToMoneyString(Number(pix?.value ?? "0"))
              )}
            </div>
            <div className={styles.divisor} />
            <div>
              Expira em: <b>15min</b>
            </div>
          </div>
        </div>
      )}
      <div
        className={styles.pixFooter}
        style={{ marginBottom: isMobile ? 16 : undefined }}
      >
        {showPix &&
          (loading || !pix ? (
            <span className={styles.code}>
              <Skeleton
                width={"100%"}
                height={40}
                variant="rectangular"
                style={{ borderRadius: 8, margin: "14px 0" }}
              />
            </span>
          ) : (
            <p>{pix?.qrCodeLink}</p>
          ))}
        {!pix ? (
          <div>
            <Button
              size="large"
              variant="contained"
              onClick={generatePix}
              disabled={loading}
            >
              {loading ? <Load /> : "Gerar pix"}
            </Button>
          </div>
        ) : (
          <div>
            <div
              onClick={copyPixToClipboard}
              className={!pix?.qrCodeLink ? styles.disabled : ""}
            >
              <Copy />
              Copiar código
            </div>
            <div>Aguardando confirmação!</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pix;
