import { ReactComponent as Ticket } from "assets/icons/boleto.svg";
import { ReactComponent as Credit } from "assets/icons/credit.svg";
import { ReactComponent as Pix } from "assets/icons/Pix.svg";
import { useUi } from "context/ui/UIContext";
import { moneyMaskNumber } from "cross-cutting/masks/money";
import { PaymentTypeEnum } from "models/paymentLink/IPaymentLinkResponse";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { UsePaymentLink } from "./_UsePaymentLink";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Payment from "./components/payment/Payment";
import styles from "./PaymentLink.module.scss";
import { TextField } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";

export interface IPaymentLinkProps {}

const PaymentLink: FC<IPaymentLinkProps> = () => {
  const valueRef = useRef<HTMLInputElement>(null);
  const { paymentLink, handleChangeValue, inputMoneyMask, paymentLinkValue } =
    UsePaymentLink(valueRef);
  const { isMobile } = useUi();
  const [imageSrc, setImageSrc] = useState<string>();

  const { enableCredit, enablePix, enableTicket } = useMemo(
    () => ({
      enableCredit: paymentLink?.paymentTypes.find(
        (it) => it.type === PaymentTypeEnum.CREDIT
      ),
      enableTicket: paymentLink?.paymentTypes.find(
        (it) => it.type === PaymentTypeEnum.TICKET
      ),
      enablePix: paymentLink?.paymentTypes.find(
        (it) => it.type === PaymentTypeEnum.PIX
      ),
    }),
    [paymentLink]
  );

  const [selectedPaymentType, setSelectedPaymentType] =
    useState<PaymentTypeEnum | null>(null);

  useEffect(() => {
    if (enableCredit) {
      setSelectedPaymentType(PaymentTypeEnum.CREDIT);
    } else if (enablePix) {
      setSelectedPaymentType(PaymentTypeEnum.PIX);
    } else if (enableTicket) {
      setSelectedPaymentType(PaymentTypeEnum.TICKET);
    }
  }, [enableCredit, enablePix, enableTicket]);

  const handleSelectType = useCallback((type: PaymentTypeEnum) => {
    setSelectedPaymentType(type);
  }, []);

  const disableButtons = useMemo(
    () =>
      paymentLink?.isVariable &&
      (!paymentLinkValue ||
        parseFloat(
          paymentLinkValue.replace(/[R$\s.]/g, "").replace(",", ".")
        ) < 1),
    [paymentLink, paymentLinkValue]
  );

  useEffect(() => {
    if (disableButtons) {
      setSelectedPaymentType(null);
    }
  }, [disableButtons]);

  useEffect(() => {
    const loadImage = async () => {
      const imageUrl = `${process.env.REACT_APP_IMAGE_URL}mepay-link/${paymentLink?.id}.png`;
      try {
        const response = await fetch(imageUrl);
        if (response.ok) {
          setImageSrc(imageUrl);
        }
      } catch {}
    };

    loadImage();
  }, [paymentLink]);

  return (
    <div id={styles.PaymentLink}>
      {paymentLink ? (
        <>
          <Header image={imageSrc} name={paymentLink.product.nome} />
          <div
            className={styles.content}
            style={{
              flexDirection: isMobile ? "column" : "row",
              minHeight: isMobile ? undefined : 497,
            }}
          >
            <div
              className={styles.info}
              style={{
                width: isMobile ? 350 : undefined,
                gap: isMobile ? 6 : undefined,
              }}
            >
              {imageSrc && (
                <img
                  src={imageSrc}
                  alt="Foto_produto"
                  style={{
                    alignSelf: isMobile ? "center" : undefined,
                    marginBottom: isMobile ? 0 : undefined,
                    marginTop: isMobile ? 16 : undefined,
                  }}
                />
              )}
              <div className={styles.name}>{paymentLink?.product.nome}</div>
              <div className={styles.description}>
                {paymentLink?.description}
              </div>
              {paymentLink.isVariable ? (
                <div className={styles.variableValue}>
                  <label>
                    Insira o valor<span> *</span>
                  </label>
                  <TextField
                    inputRef={valueRef}
                    size="small"
                    placeholder={"R$ 0,00"}
                    value={inputMoneyMask(paymentLinkValue)}
                    variant="outlined"
                    name={"value"}
                    onChange={handleChangeValue}
                  />
                </div>
              ) : (
                <div className={styles.value}>
                  {moneyMaskNumber(paymentLink.price)}
                </div>
              )}
              <div className={styles.paymentForm}>
                <p>Forma de pagamento:</p>
                <div>
                  {enableCredit && (
                    <Tooltip title="Valor mínimo R$ 1,00">
                      <div
                        className={
                          selectedPaymentType === PaymentTypeEnum.CREDIT
                            ? styles.creditIconActive
                            : styles.creditIconInactive
                        }
                      >
                        <div
                          onClick={() =>
                            handleSelectType(PaymentTypeEnum.CREDIT)
                          }
                          className={
                            disableButtons || paymentLink.price < 1
                              ? styles.disabled
                              : ""
                          }
                          style={{ height: isMobile ? 106 : undefined }}
                        >
                          <Credit />
                          <p>Crédito</p>
                        </div>
                      </div>
                    </Tooltip>
                  )}
                  {enablePix && (
                    <Tooltip title="Valor mínimo R$ 1,00">
                      <div
                        className={
                          selectedPaymentType === PaymentTypeEnum.PIX
                            ? styles.pixIconActive
                            : styles.pixIconInactive
                        }
                      >
                        <div
                          onClick={() => handleSelectType(PaymentTypeEnum.PIX)}
                          className={
                            disableButtons || paymentLink.price < 1
                              ? styles.disabled
                              : ""
                          }
                          style={{ height: isMobile ? 106 : undefined }}
                        >
                          <Pix style={{ margin: 8 }} />
                          <p>PIX</p>
                        </div>
                      </div>
                    </Tooltip>
                  )}
                  {enableTicket && (
                    <Tooltip title="Valor mínimo R$ 5,00">
                      <div
                        className={
                          selectedPaymentType === PaymentTypeEnum.TICKET
                            ? styles.ticketIconActive
                            : styles.ticketIconInactive
                        }
                      >
                        <div
                          onClick={() =>
                            handleSelectType(PaymentTypeEnum.TICKET)
                          }
                          className={
                            disableButtons || paymentLink.price < 5
                              ? styles.disabled
                              : ""
                          }
                          style={{ height: isMobile ? 106 : undefined }}
                        >
                          <Ticket />
                          <p>Boleto</p>
                        </div>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            {!isMobile && <div className={styles.divisor} />}
            <Payment
              selectedPaymentType={selectedPaymentType}
              paymentLink={paymentLink}
            />
          </div>
        </>
      ) : (
        <div />
      )}
      <Footer />
    </div>
  );
};
export default PaymentLink;
